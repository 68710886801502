import { LandingPageScreenScaffold } from "../scaffold/LandingPageScreenScaffold"
import { LandingPageView } from "../view/LandingPageView"

export const LandingPageScreen = ({landingPage}) => {

    return (
        <LandingPageScreenScaffold> {/* contentWrappingDisabled because LandingPage has own width settings */} 
            <LandingPageView landingPage={landingPage} />
        </LandingPageScreenScaffold>
    )

}