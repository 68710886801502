import Head from 'next/head';

import {
    createTheme,
    ThemeProvider,
    StyledEngineProvider,
} from '@mui/material';
import { renderContent, renderItem } from './LandingPageModuleRenderer';
import { FavIcon } from 'modules/picasso-ui/scaffold/FavIcon';
import { orElse } from 'utils/objectUtils';
import { useLandingPageFooter } from './utils/useLandingPageFooter';

export interface RenderProps {
    itemIdsEnabled?: boolean
}

export default function LandingPageModule({page, rootPage, containerAllowed, populateFooter = true, renderProps = {}}:{page: any, rootPage?:any, containerAllowed?: boolean, populateFooter?: boolean, renderProps?: RenderProps}) {

    const { populatePageFooter } = useLandingPageFooter()

    containerAllowed = orElse(containerAllowed, true)

    const theme = page.theme ? createTheme(page.theme) : null;

    const contentEffective = populateFooter ? populatePageFooter(page) : [...page.content]
    
    return <>
        {page?.title && (
            <Head>
                <title>{page.title}</title>
            </Head>
        )}
        {theme && (
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    {page?.favIconSrc && (
                        <FavIcon favIconSrc={page.favIconSrc}/>
                    )}
                    {page.appBar &&
                        renderItem(page.appBar, rootPage, null)
                    }
                    {renderContent(contentEffective, containerAllowed, page.maxWidth, rootPage, true, renderProps.itemIdsEnabled)}
                </ThemeProvider>
            </StyledEngineProvider>
        )}
        {!theme && (
            <>
                {page?.favIconSrc && (
                    <FavIcon favIconSrc={page.favIconSrc}/>
                )}
                {page.appBar &&
                    renderItem(page.appBar, rootPage, null)
                }
                {renderContent(contentEffective, containerAllowed, page.maxWidth, rootPage, true, renderProps.itemIdsEnabled)}
            </>
        )}
    </>;

}