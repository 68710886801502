import { handleRequest } from 'modules/yoio-modules/core/pageRequestHandler';
import { LandingPageScreen } from 'modules/yoio/screen/LandingPageScreen';
import { useApp } from 'modules/yoio/useApp';
import { LayoutScreenContentCentered } from 'components/layout/positioning/LayoutScreenContentCentered';
import { PageType, YoioPageProps } from 'modules/yoio-modules/core/PageTypes';
import { GetServerSidePropsResult } from 'next';

export default function Page({landingPage}) {

  const { app } = useApp()

  /** @ts-ignore */
  if (app.landingPageText) {
    /** @ts-ignore */
    return <LayoutScreenContentCentered>{app.landingPageText}</LayoutScreenContentCentered>
  }
  
  return (
    <LandingPageScreen landingPage={landingPage} />
  )

}

export async function getServerSideProps(context):Promise<GetServerSidePropsResult<YoioPageProps>> {

    const props = await handleRequest(context, {
      yoioPageSettings: {
        pageType: PageType.landing,
      }
    }) as YoioPageProps

    if (props.errorResponse) {
      return props.errorResponse
    }

    // site.com and site.com/en -> canonical URL must be site.com
    if (props.yoioPageSettings && !props.yoioPageSettings.canonicalUrl) {
      if (props.landingPage?.localeEffective === 'en') {
        props.yoioPageSettings.canonicalUrl = props.app.uiUrl;
      }
    }

    /**
     * Attention: any intermediate proxy (nginx, cloudfront) will respect this header and cache the response.
     * Currently this is self-deployed nginx.
     * No user data is allowed to be in this page.
     */
    context.res.setHeader(
      'Cache-Control',
      'public, max-age=120, s-maxage=600'
    )

    return {
      props: {
        ...props,
      }
    }
}